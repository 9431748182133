import React from "react"
import { Link } from "gatsby"

interface ISImpleLinkButton {
  linkTitle: string
  linkDestination: string
  isDarkMode?: boolean
  isNewTab?: boolean
}

const SimpleLinkButton = ({
  linkTitle,
  linkDestination,
  isDarkMode = false,
  isNewTab = false,
}: ISImpleLinkButton) => {
  return (
    <Link
      className={`uppercase py-unit2 px-unit4 rounded-full border-solid border-2 border-horizonGrey font-bold self-start
            ${
              !isDarkMode
                ? `bg-white text-horizonGrey visited:text-horizonGrey hover:bg-horizonLightGrey border-solid border-horizonDarkGrey border-[3px]`
                : `bg-horizonGrey text-white visited:text-white hover:bg-horizonDarkGrey`
            }`}
      to={linkDestination}
      target={isNewTab ? `_blank` : `_self`}
      rel={isNewTab ? `noopener noreferrer` : ""}
    >
      {linkTitle}
    </Link>
  )
}

export default SimpleLinkButton
