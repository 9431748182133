import React from "react"
import DefaultCardImageComponent from "../components/cms/default-card-image"
import { DashboardContainer } from "../components/dashboard-container"
import { Header } from "../components/header"
import ColSpanSix from "../components/tailwind-grid/col-span-six"
import GridSystem from "../components/tailwind-grid/grid"
import { graphql } from "gatsby"

const AdminDashboard = ({ data }: Record<string, any>) => {
  return (
    <>
      <Header currentPage="admin-dashboard" />
      <div className="mt-10">
        <DashboardContainer>
          <h1 className="text-[30px] lg:text-[45px] font-bold">
            Admin Dashboard
          </h1>
          <p className="mb-[48px]">
            Please Select which dashboard you would like to view
          </p>
          <GridSystem>
            {data?.allKontentItemInternalAuthenticatedDashboardLandingpage?.edges[0]?.node?.elements?.landing_page_cards?.value?.map(
              (card: Record<string, any>) => {
                return (
                  <ColSpanSix key={card.id}>
                    <DefaultCardImageComponent
                      icon={card.elements.icon.value[0].url}
                      image={card.elements.image.value[0].url}
                      linkTile={card.elements.cta_label.value}
                      linkDestination={card.elements.cta_link.value}
                      isDarkMode={
                        card.elements.theme.value[0].codename === "dark"
                          ? true
                          : false
                      }
                      heading={card.elements.heading.value}
                      body={card.elements.body.value}
                      isLinkNewTab={
                        card.elements.cta_behaviour.value[0].codename ===
                        "open_in_new_tab"
                          ? true
                          : false
                      }
                    />
                  </ColSpanSix>
                )
              }
            )}
          </GridSystem>
        </DashboardContainer>
      </div>
    </>
  )
}

export const query = graphql`
  query AdminDashboardQuery {
    allKontentItemInternalAuthenticatedDashboardLandingpage {
      edges {
        node {
          elements {
            landing_page_cards {
              value {
                ... on kontent_item_internal_card {
                  id
                  elements {
                    body {
                      value
                    }
                    cta_behaviour {
                      value {
                        name
                        codename
                      }
                    }
                    cta_label {
                      value
                    }
                    cta_link {
                      value
                    }
                    dashboard_roles {
                      value {
                        ... on kontent_item_internal_dashboard_role {
                          id
                          system {
                            name
                          }
                        }
                      }
                    }
                    heading {
                      value
                    }
                    icon {
                      value {
                        url
                        height
                        width
                      }
                    }
                    image {
                      value {
                        url
                        width
                        height
                      }
                    }
                    image_alt_tag {
                      value
                    }
                    theme {
                      value {
                        name
                        codename
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default AdminDashboard
