import React from "react"
import SimpleLinkButton from "../simple-link-button"
interface IDefaultCardImageComponent {
  icon?: string
  image?: string
  linkTile?: string
  linkDestination?: string
  isDarkMode?: boolean
  heading?: string
  body?: string
  isLinkNewTab?: boolean
}

const DefaultCardImageComponent = ({
  icon,
  image,
  linkTile,
  linkDestination,
  isDarkMode = true,
  heading,
  body,
  isLinkNewTab = false,
}: IDefaultCardImageComponent) => {
  return (
    <div className="flex flex-wrap justify-center w-full h-full">
      {image && (
        <div
          className={`flex flex-col lg:min-h-[444px] min-h-[300px] lg:w-[50%] w-full`}
          style={{
            background: `url("${image}") no-repeat`,
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        ></div>
      )}
      <div
        className={`flex flex-col lg:min-h-[444px] min-h-[300px] lg:w-[50%] w-full justify-center bg-[center_75%] bg-no-repeat bg-[length:250%] 
            ${
              isDarkMode
                ? `bg-horizonhover text-white bg-horizonLift`
                : `bg-horizonLightGrey text-horizonDarkGrey bg-horizonLiftInverted`
            }`}
      >
        <div className={`flex flex-col flex-auto justify-center p-unit4 z-[1]`}>
          {icon && (
            <img
              className="mb-unit2 w-[48px] h-[48px] rounded-full relative"
              src={icon}
            />
          )}
          {heading && (
            <h4 className="uppercase mb-unit1 text-2xl font-bold">{heading}</h4>
          )}
          {body && <p className="mb-unit3">{body}</p>}
          {linkTile && linkDestination && (
            <SimpleLinkButton
              linkDestination={linkDestination}
              linkTitle={linkTile}
              isDarkMode={isDarkMode}
              isNewTab={isLinkNewTab}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default DefaultCardImageComponent
